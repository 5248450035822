// -----------------------------
// CB Forms Validation
// -----------------------------

.cb-validation-state,
.cb-validation-success,
.cb-validation-error {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;

  &.cb-textarea {
    align-items: flex-start;
  }
  .cb-input-icon-right,
  .cb-input-icon-left,
  .cb-validation-label-input {
    position: relative;
    flex-grow: 1;
  }

  .cb-validation-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 3em;
    height: 3rem;
    line-height: 1em;
  }

  &.cb-input-condensed,
  &.cb-condensed {
    .cb-validation-icon {
      width: 2.5em;
      height: 2.5rem;
    }
  }
}

fieldset {
  &.cb-validation-state,
  &.cb-validation-success,
  &.cb-validation-error {
    flex-direction: column;
    align-items: flex-start;
  }
}

.cb-validation-success {
  .cb-validation-icon {
    &.cb-glyph,
    &.cb-icon {
      color: $success-color;
    }
  }
}

.cb-validation-success {
  .cb-input-helper,
  &.cb-input-helper {
    align-items: start;

    &::before {
      font-size: 1rem;
      line-height: 1em;
      margin-right: 8px;
      @include glyph-font(CB Icons);
      content: "\e951";
      color: $gray4;
    }
  }
}

.cb-validation-error {
  @include form-control-validation($error-color, $error-color);

  label {
    &.cb-value-fl,
    &.cb-focus-fl {
      color: $error-color;
    }
  }

  .cb-input-helper,
  &.cb-input-helper {
    align-items: start;

    &::before {
      font-size: 1rem;
      line-height: 1em;
      margin-right: 8px;
      @include glyph-font(CB Icons);
      content: "\e952";
      color: $error-color;
    }
  }
}

.cb-validation-error {
  .cb-validation-icon {
    &.cb-glyph,
    &.cb-icon {
      color: $error-color;
    }
  }
}

.cb-input-helper-block {
  // backward compatibility
  &:not(.cb-notification) {
    transition: height $transition-time-l ease;
    width: calc(100% - 48px);

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      opacity: 0;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;

      li {
        &.cb-input-helper {
          display: flex;
          align-items: flex-start;
          flex-wrap: nowrap;

          p,
          span {
            color: $gray1;
            font-size: $font-size-xsmall;
            line-height: 1.3333333333333333em;
          }

          &::before {
            transition: all $transition-time-l ease-in-out;
          }

          &:not(.cb-validation-error):not(.cb-validation-success) {
            &::before {
              font-size: 1rem;
              line-height: 1em;
              margin-right: 8px;
              @include glyph-font(CB Icons);
              content: "\e951";
              color: $gray4;
            }
          }

          &.cb-validation-success {
            &::before {
              @include glyph-font(CB Icons);
              content: "\e951";
              animation-name: validIcon;
              animation-duration: 0.25s;
              animation-timing-function: ease-in-out;
              animation-fill-mode: forwards;
            }
          }

          &.cb-validation-error {
            p,
            span {
              color: $red1;
            }
            &::before {
              @include glyph-font(CB Icons);
              content: "\e952";
              color: $gray4;
            }
          }
        }
      }
    }

    &.cb-show-err-state {
      ul {
        li {
          &.cb-input-helper {
            &.cb-validation-error {
              &::before {
                @include glyph-font(CB Icons);
                content: "\e952";
                color: $error-color;
              }
            }
          }
        }
      }
    }

    &.cb-hidden {
      height: 0;
      overflow-y: hidden;

      ul {
        animation-name: fadeOut;
      }
    }

    &.cb-show {
      overflow-y: auto;
      height: auto;

      ul {
        animation-name: fadeIn;
      }
    }
  }

  // Error Block Display
  // backward compatibility
  &.cb-notification {
    ul {
      li {
        a {
          &.cb-input-helper {
            font-size: 1rem;
            line-height: 1.5em;
            display: block;

            &::before {
              margin-right: 0;
              content: "";
            }

            &.cb-validation-success {
              &::before {
                margin-right: 0;
                content: "";
              }
            }

            &.cb-validation-error {
              &::before {
                margin-right: 0;
                content: "";
              }
            }
          }
        }
      }
    }
  }
}

@keyframes validIcon {
  from {
    content: "\e951";
    color: $success-color;
  }

  to {
    content: "\e951";
    color: $gray4;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
