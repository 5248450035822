// -----------------------------
// CB Typography Mixins
// -----------------------------

@import "../mixins/link.scss";

@mixin list-style() {
  ul,
  ol {
    li {
      list-style: circle;
    }
    ul,
    ol {
      li {
        text-indent: -5px;
        list-style-type: none;
        &::before {
          content: "-";
          position: relative;
          left: -12px;
        }
      }
    }
  }
}

@mixin list-checkbox() {
  li {
    list-style: none;
    position: relative;
    padding-left: 8px;

    &::before {
      @include glyph-font(CB Icons);
      font-size: 1rem;
      position: absolute;
      left: -17px;
      top: 4px;
      content: "\e94e";
    }
  }
}

@mixin list-checkmark() {
  li {
    list-style: none;
    position: relative;
    padding-left: 8px;

    &::before {
      @include glyph-font(CB Icons);
      font-size: 1rem;
      position: absolute;
      left: -17px;
      top: 4px;
      content: "\e953";
    }
  }
}

@mixin cb-text-list-feature() {
  border: 1px solid $border-color;
  padding-left: 0;
  li {
    list-style: none;
    padding: 16px;
    border-bottom: 1px solid $border-color;
    &:last-of-type {
      border-bottom: 0;
    }
  }
}

@mixin cb-text-list-checkmark() {
  li {
    p {
      display: inline;
    }
    &::before {
      position: unset;
      top: 0;
      left: 0;
      @include glyph-font(CB Icons);
      font-size: 1rem;
      content: "\e953";
      margin-right: 8px;
    }
  }
}

@mixin list-dash() {
  li {
    list-style: none;
    position: relative;
    padding-left: 8px;

    &::before {
      font-size: 1rem;
      position: absolute;
      left: -17px;
      top: 0;
      content: "\2014";
    }
  }
}

// ------------------  META
@mixin cb-text-meta() {
  font-size: 0.75rem; //12
  line-height: 1.3333333333333333em; //16
  font-weight: 500;
}

@mixin cb-text-meta-large() {
  font-size: $font-size-small; //14
  line-height: $line-height-small; //24
  font-weight: 500;
}

// ------------------  PARAGRAPH
@mixin cb-paragraph2-res() {
  // xs
  font-size: 1.3125rem; //21
  line-height: 1.1428571428571428em; //24

  // sm, md
  @include media-breakpoint-up("sm", $grid-breakpoints) {
    font-size: 1.5rem; //24
    line-height: 1.3333333333333333em; //32
  }

  // lg and up
  @include media-breakpoint-up("lg", $grid-breakpoints) {
    font-size: 2rem; //32
    line-height: 1.25em; //40
  }
}
