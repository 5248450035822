// -----------------------------
// CB Tabs
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/menu-list.scss";

.cb-tabs-menu {
  display: flex;
  height: 72px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    height: 100%;

    li {
      list-style: none;
      a {
        @include horizontal-menu-link();
        text-decoration: none !important;

        &.cb-disabled,
        &:disabled {
          cursor: not-allowed;

          opacity: 0.5;
          text-decoration: none !important;
          background-color: $gray5;
          border-color: $gray5;
          color: $gray3;
        }
      }
    }
  }
  &.cb-condensed {
    height: 48px;
    ul {
      li {
        a {
          @include horizontal-menu-link(1);
        }
      }
    }
  }

  &.cb-tabs-menu-icon {
    height: auto;

    ul {
      li {
        a {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: center;

          [class^="cb-glyph"],
          [class^="cb-icon"] {
            margin-bottom: 8px;
            margin-top: 10px;
            font-size: 26px;
          }
        }
      }
    }
    &.cb-condensed {
      height: auto;
      ul {
        li {
          a {
            [class^="cb-glyph"],
            [class^="cb-icon"] {
              margin-bottom: 8px;
              margin-top: 16px;
              font-size: 1rem;
            }
          }
        }
      }
    }
    &.cb-tabs-menu-icon-only {
      ul {
        li {
          a {
            padding: 0;
            [class^="cb-glyph"],
            [class^="cb-icon"] {
              margin: 1rem;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  & + .cb-tabs-container {
    margin-top: 48px;
  }
}

.cb-tabs-container {
  .cb-tabs-content {
    display: none;

    &.cb-selected {
      display: block;
    }
  }
}

[data-cb-input-m="keyboard"] {
  .cb-tabs-menu {
    a {
      &.cb-menu-link {
        &:focus,
        &.cb-focus {
          background-color: $gray5;
          outline: auto !important;
          outline-color: $outlineColor !important;
        }
      }
    }
  }
}
