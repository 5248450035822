// -----------------------------
// CB Stepper Mixins
// -----------------------------

@mixin cb-v-stepper() {
  ul,
  ol {
    flex-direction: column;

    li {
      display: flex;
      align-items: center;
      flex-flow: wrap;

      a {
        display: flex;
        align-items: center;
        flex-flow: wrap;
      }

      text-align: left;
      padding: 0;
      margin-bottom: 44px;

      p {
        padding-left: 16px;

        & + p {
          flex: 100%;
          padding-left: 48px;
        }
      }

      &::after {
        height: 100%;
        width: 2px;
        top: 38px;
        left: 15px;
      }

      &:last-child {
        &::after {
          width: 0;
          height: 0;
        }
      }
    }
  }

  &.cb-stepper-content-glyph {
    ul,
    ol {
      li {
        margin-bottom: 64px;

        &::after {
          height: calc(100% - 16px);
          width: 3px;
          top: 72px;
          left: 31px;
        }
      }
    }
  }
}

@mixin glyph-multi-content-color-stepper($name, $color1, $color2, $bk: null) {
  &.cb-glyph-#{$name} {
    ul,
    ol {
      li {
        .cb-glyph-content-multi {
          @include glyph-multi-content-color($color1, $color2, $bk);
        }

        &.cb-stepper-skip {
          .cb-glyph-content-multi {
            @include glyph-multi-content-color($white, $white, $gray2);
          }
          &::after {
            background: $bk;
          }
        }

        &.cb-stepper-complete {
          &::after {
            background: $bk;
          }
        }
      }
    }
  }
}
