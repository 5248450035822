// -----------------------------
// CB Breadcrumb
// -----------------------------

 @import "apricot-variable.scss";
 @import "../mixins/link.scss";

.cb-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 24px 0;
  font-size: $font-size-small;
  line-height: $line-height-small;
  a {
    @include black-link();
  }
  li{
    &:first-of-type:not(:last-of-type) {
      &::after{
        content: "/\00a0";
        padding: 0 12px;
        color: $font-color-base;
      }
    }
    &+li{
      &::after {
        content: "/\00a0";
        padding: 0 12px;
        color: $font-color-base;
      }
      &:last-of-type {
        &::after{
          content: '';
          padding: 0;
        }
      }
    }
  }
}