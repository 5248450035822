// -----------------------------
// CB Pagination
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/link.scss";

.cb-pagination {
  padding: 0;
  margin: 0;
  font-size: 0;
  display: inline-flex;
  flex-wrap: wrap;
  a {
    @include black-link();
    &.cb-disabled,
    &:disabled {
      color: $gray3;
    }
  }
  & > li {
    display: inline-flex;
    .cb-btn-square {
      border-color: $gray4;
      border-radius: 0;
      border-left-width: 0;
      border-right-width: 0;
      &.cb-active {
        background-color: $gray3;
        border: 1px solid $gray4 !important;
      }

      &:focus,
      &.cb-focus {
        border: 1px solid $black1;
      }
      &:disabled,
      &.cb-disabled {
        &.cb-active,
        &.active {
          color: $gray5;
        }
      }
    }
    &:first-child {
      .cb-btn-square {
        border-left-width: 1px;
        border-top-left-radius: $btn-border-radius-input;
        border-bottom-left-radius: $btn-border-radius-input;
      }
    }
    &:last-child {
      .cb-btn-square {
        border-right-width: 1px;
        border-top-right-radius: $btn-border-radius-input;
        border-bottom-right-radius: $btn-border-radius-input;
      }
    }
  }
}
