// -----------------------------
// CB Local Navigation
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/link.scss";

@mixin mobileLayout() {
  .cb-mobile-panel {
    display: block;
    z-index: 901;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: calc(-100% - 40px);

    transition: all 0.25s;
    transition-delay: 0s;

    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);

    padding: 16px 0 24px 0;
    background-color: $white;
    .cb-panel-navigation {
      display: flex;
      padding: 0 16px 16px 24px;

      border-bottom: 1px solid $gray4;

      .cb-close-link,
      .cb-back-link {
        @include black-link();
        line-height: 16px;
        text-decoration: none;

        &:hover,
        &:focus,
        &:visited {
          text-decoration: none;
        }
      }

      .cb-back-link {
        font-size: 0.875rem;
        line-height: 16px;
        font-weight: 500;
      }

      .cb-close-link {
        margin-left: auto;
      }
    }
    .cb-menu-list-vertical {
      overflow-y: auto;
      height: calc(100vh - 48px);
    }

    .cb-menu-link {
      padding-right: 16px;
    }

    .cb-nested-navigation {
      .cb-menu-link {
        display: flex;
        justify-content: space-between;
        font-weight: 500;
      }
    }

    &.cb-panel-show {
      left: 0;
      transition: all 0.25s ease-in-out;
    }
  }
}

.cb-local-navigation {
  &.cb-sticky-local-navigation {
    width: 100%;
    z-index: 901;
    position: sticky;
    top: 0;
    left: 0;
  }

  > .cb-desktop-navigation {
    > .row {
      @include media-breakpoint-only("xs", $grid-breakpoints) {
        margin-right: -24px;
        .col-xs {
          &.cb-nav-container {
            padding-right: 0;
          }
        }
      }
    }

    .cb-nav-container {
      display: flex;
      justify-content: space-between;

      .cb-site-name {
        display: inline-flex;
        justify-content: flex-start;
        min-width: fit-content;

        @include media-breakpoint-only("xs", $grid-breakpoints) {
          height: 48px;
          align-items: center;
        }

        a {
          font-size: 1.4375rem;
          line-height: 1.0434782608695652em;
          font-weight: 500;
          text-decoration: none;
          padding: 36px 24px 12px 0;
          white-space: nowrap;

          @include black-link();

          &:hover,
          &:focus,
          &:visited {
            text-decoration: underline;
          }

          @include media-breakpoint-only("xs", $grid-breakpoints) {
            font-size: 1.1875rem;
            line-height: 1.263157894736842em;
            padding: 0;
          }
        }
      }

      .cb-nav-items {
        display: inline-flex;
        flex-basis: 100%;
        justify-content: flex-end;
        height: 72px;

        .cb-menu-list-horizontal {
          width: 100%;

          > ul {
            width: 100%;
            justify-content: flex-end;

            > li {
              display: flex;
              justify-content: flex-end;

              &.cb-promotional {
                &.cb-last-of-type {
                  margin-right: auto;
                }
              }

              .cb-menu-link {
                white-space: nowrap;
              }

              .cb-dropdown {
                > a {
                  &:focus {
                    outline: auto;
                    outline-color: $outlineColor;
                  }
                }
                .cb-dropdown-toggle {
                  height: 100%;
                  white-space: nowrap;

                  &:hover,
                  &.cb-hover {
                    background-color: $gray5;
                  }
                }

                .cb-dropdown-menu {
                  ul {
                    flex-direction: column;

                    > li {
                      > a {
                        &:hover {
                          box-shadow: inset 2px 0px 0px 0px #1e1e1e;
                        }
                        &.cb-selected {
                          box-shadow: inset 2px 0px 0px 0px $black1;
                        }
                      }
                    }

                    .cb-nested-menu {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cb-mobile-trigger {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-left: 1px solid $gray4;
        padding: 16px;
        min-width: fit-content;

        a {
          @include black-link();

          &:hover,
          &:focus,
          &:visited {
            text-decoration: none;
          }
        }
      }
    }
  }

  > .cb-mobile-navigation {
    .cb-mobile-panel {
      display: none;
    }

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      @include mobileLayout();
    }
  }

  // when we activate mobile layout for tablet
  &.cb-tablet-local-navigation {
    @include media-breakpoint-down("sm", $grid-breakpoints) {
      > .cb-desktop-navigation {
        max-width: 100%;
        > .row {
          margin-right: -24px;
          .col-xs {
            &.cb-nav-container {
              padding-right: 0;
            }
          }
        }
        .cb-nav-container {
          .cb-site-name {
            height: 48px;
            align-items: center;
            a {
              font-size: 1.1875rem;
              line-height: 1.263157894736842em;
              padding: 0;
            }
          }
        }
      }

      > .cb-mobile-navigation {
        @include mobileLayout();
      }
    }
  }
}

.cb-sticky-local-navigation {
  .cb-local-navigation {
    > .cb-desktop-navigation {
      z-index: 901;
      position: fixed;
      top: 0;
      left: 0;
    }
  }
}

.cb-local-navigation-lock {
  overflow-y: hidden;
}

// ------------------------------------ FOCUS
[data-cb-input-m="keyboard"] {
  .cb-local-navigation {
    > .container-fluid {
      .cb-menu-list-horizontal {
        > ul {
          > li {
            .cb-dropdown {
              .cb-dropdown-toggle {
                &:focus,
                &.cb-focus {
                  text-decoration: underline !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
