// -----------------------------
// CB Glyph Logos
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/glyph.scss";

.cb-glyph-logo {
  position: relative;
	display: inline-block;
  background-image: none!important;
  @include glyph-font(CB Glyphs Logo);
	&::before {
	  position: absolute;
	  top: 0;
	  left: 0;
	}
  @each $key, $value in $logoGlyphs {
    &.cb-#{$key} {
      @include glyph-content($value);
    }
  }
}

