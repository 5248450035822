// -----------------------------
// CB Print
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/utils.scss";
@import "../mixins/print.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/grid-framework";
@import "../mixins/grid";
@import "../mixins/event-timeline.scss";
@import "../mixins/table.scss";

@media print {
  @include print-page();
  @include print-apricot();
  @include print-modal();
  @include print-iOS-apricot();
}

.cb-print {
  @include print-page();
  @include print-apricot();
  @include print-iOS-apricot();
  &.cb-modal-open {
    * {
      visibility: hidden;
    }

    .cb-modal {
      &.cb-open {
        visibility: visible !important;

        .cb-modal-overlay {
          visibility: visible !important;
          justify-content: flex-start;
          align-items: flex-start;

          * {
            visibility: visible !important;
          }

          .cb-modal-container {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }

          .cb-modal-header {
            .cb-btn-close {
              display: none !important;
            }
          }

          .cb-modal-content {
            height: auto !important;
          }
        }
      }
    }
  }
}

// ------------- Change layout only for namespace
@media print {
  body.cb-print-full {
    min-width: 976px !important;
  }

  .cb-print-full {
    .container {
      min-width: 976px !important;
      max-width: 976px !important;
    }
  }

  // two column

  body.cb-print-col-2 {
    min-width: 976px !important;
  }
  .cb-print-col-2 {
    .container {
      min-width: 976px !important;
      max-width: 976px !important;
    }
    .cb-band {
      .container {
        min-width: auto !important;
        max-width: auto !important;
      }
    }

    @include print-layout();
    .cb-print-all {
      display: block !important;
    }
  }
}

.cb-print {
  &.cb-print-full {
    min-width: 976px !important;
    .container {
      min-width: 976px !important;
      max-width: 976px !important;
    }
  }

  // two column

  &.cb-print-col-2 {
    min-width: 976px !important;
    .container {
      min-width: 976px !important;
      max-width: 976px !important;
    }
    .container {
      min-width: 976px !important;
      max-width: 976px !important;
    }

    .cb-band {
      .container {
        min-width: auto !important;
        max-width: auto !important;
      }
    }

    @include print-layout();

    .cb-print-all {
      display: block !important;
    }
  }
}
