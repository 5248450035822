// -----------------------------
// CB Text width Media
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/font.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/typography.scss";
@import "../mixins/link.scss";

// ------------------------------------ TEXT AND ICON
// medium and basic
.cb-text-icon-left {
  font-size: $font-size-base;
  line-height: $line-height-base;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}

.cb-text-icon-right {
  font-size: $font-size-base;
  line-height: $line-height-base;

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;
  }
}

// small

.cb-text-icon-left-sm,
.cb-text-icon-left-14 {
  font-size: 0.875rem !important; //14
  line-height: 1.1428571428571428em !important; //16

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}


.cb-text-icon-right-sm,
.cb-text-icon-right-14 {
  font-size: 0.875rem !important; //14
  line-height: 1.1428571428571428em !important; //16

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;
  }
}

// xs
.cb-text-icon-left-xs, 
.cb-text-icon-left-12 {
  font-size: 0.75rem !important; //12
  line-height: 1.3333333333333333em !important; //16

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
  }
}

.cb-text-icon-right-xs,
.cb-text-icon-right-12 {
  font-size: 0.75rem !important; //12
  line-height: 1.3333333333333333em !important; //16

  .cb-glyph,
  .cb-icon {
    margin-left: 8px;
    font-size: 0.625rem !important; //10
    line-height: 1.6em !important; //16
  }
}

a {

  &.cb-text-icon-left,
  &.cb-text-icon-right,
  &.cb-text-icon-left-md,
  &.cb-text-icon-right-md,
  &.cb-text-icon-left-sm,
  &.cb-text-icon-right-sm,
  &.cb-text-icon-left-xs,
  &.cb-text-icon-right-xs {
    text-decoration: none;

    &:hover,
    &.cb-hover,
    &:active,
    &.cb-active {
      text-decoration: none !important;

      span:not(.cb-glyph),
      span:not(.cb-icon) {
        text-decoration: underline;
      }
    }
  }
}



// ------------------------------------ OVERLINE
.cb-text-icon-overline {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  .cb-glyph,
  .cb-icon {
    margin-right: 8px;
    color: $blue5;
  }

  p,
  h4,
  h5 {
    @include roboto-medium();
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.7142857142857142em;
  }
}

// ------------------------------------ BYLINE
.cb-byline {
  display: flex;
  flex-wrap: nowrap;

  >a {
    display: flex;
    flex-wrap: nowrap;
    @include black-link();
    text-decoration: none;

    &:hover {
      p {
        text-decoration: underline;
      }
    }
  }

  .cb-glyph-avatar,
  .cb-icon-avatar {
    min-width: 2em;

    +.cb-byline-content {
      margin-left: 8px;
    }
  }

  .cb-byline-content {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      font-size: 0.875rem; //14
      line-height: 1.1428571428571428em; //16
    }
  }

  @include media-breakpoint-up('md', $grid-breakpoints) {
    .cb-glyph-avatar:not(.cb-glyph-sm),
    .cb-icon-avatar:not(.cb-glyph-sm) {
      min-width: 3em;

      +.cb-byline-content {
        margin-left: 16px;
      }
    }

    .cb-byline-content {
      .cb-meta-tags {
        @include cb-text-meta-large();
        line-height: 1.5em; // 21
      }

      p {
        font-size: 1rem;
        line-height: 1.5em;
      }
    }
  }
}

// ------------------------------------ META TAG
.cb-meta-tags,
.cb-meta-tags-large {
  list-style: none;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;

  @include cb-text-meta();
  line-height: 2em;

  p {
    @include cb-text-meta();
    line-height: 2em;
  }

  li {
    display: flex;
    align-items: center;

    &::after {
      content: "\007C";
      display: inline-flex;
      color: $gray3;
      padding: 0 12px;
    }

    &:last-of-type {
      &::after {
        content: '';
        padding: 0;
      }
    }
  }

  a {
    @include black-link();
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.cb-meta-tags-large {
  @include cb-text-meta-large();

  p {
    @include cb-text-meta-large();
  }
}

// ------------------------------------ Label Tags
.cb-card-label {
  padding: 3px;
  margin: 0;

  color: $gray1;
  font-size: $font-size-xsmall;
  line-height: 1em;
  letter-spacing: 0.5px;
  text-transform: uppercase; 
  display: inline-flex;
  max-width: fit-content;
  background-color: $white;
  border: 1px solid $gray4;
  border-radius: 4px;
}
// [class*="safari"] {
//   .cb-card-label {
//     padding: 4px 8px 10px 8px!important;
//   }
// }
// ------------------------------------ Date and Time Tags
.cb-date-time-tags {
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: wrap;
  text-transform: capitalize;
  margin-top: auto;

  p {
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;

    &+p {
      margin: 0;
    }

    &:first-of-type {
      position: relative;
      margin-right: 32px;

      &::after {
        content: " ";
        width: 1px;
        height: 24px;
        background-color: $gray4;
        position: absolute;
        right: -16px;
      }
    }

    &:first-of-type:last-of-type {
      &::after {
        background-color: transparent;
        margin: 0;
        width: 0;
      }
    }

    &.cb-event-stamp {
      background-color: $gray4;
      padding: 0 8px;
    }
  }
}

// ------------------------------------ Text and Icon
.cb-text-icon-block {
  .flex-shrink-0 {
    line-height: 0;
  }
}

// ------------------------------------ Text and Icon
.cb-score-metric {
  text-align: center;
  max-width: fit-content;

  .cb-score-label {
    font-size: $font-size-small;
    line-height: $line-height-small;
    padding: 0 24px 8px 24px;
    border-bottom: 1px solid $black1;
  }

  .cb-score-value {
    font-size: 4.5rem;
    line-height: 1em;
    padding: 8px 24px 0 24px;
  }
}


// ------------------------------------ Fix Icon

.cb-fixed-icon-top-left{
  position: relative;

  [class^="cb-glyph-content"],
  [class^="cb-icon"],
  [class^="cb-ilus"] {
    position: absolute;
    top: 24px;
    left: 24px;
  }
}

.cb-fixed-icon-top-right{
  position: relative;

  [class^="cb-glyph-content"],
  [class^="cb-icon"],
  [class^="cb-ilus"] {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}


// ------------------------------------ VIDEO OVERLAY EFFECT 

.cb-video-block {
  position: relative;
  .cb-video-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .cb-video-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cb-video-effect-col {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cb-video-block-col {
  position: relative;

  .cb-video-effect {
    position: absolute;
    top: 0;
    left: 12px;
    width: calc(100% - 24px) ;
    height: 100%;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}