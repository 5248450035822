// -----------------------------
// CB Date Picker Mixins
// -----------------------------
@import "../mixins/utils.scss";

@mixin selected-date($color: $blue5){
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Ccircle  stroke='#{str-replace(quote(#{$color}),'#','%23')}' fill='#{str-replace(quote(#{$color}),'#','%23')}' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
} 

// color1: main
// color2: tint
@mixin selected-start($color1: $blue5, $color2: $blue5Tint2){
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Crect x='16' y='0' width='120' height='32' stroke-width='1' stroke='#{str-replace(quote(#{$color2}),'#','%23')}' fill='#{str-replace(quote(#{$color2}),'#','%23')}'/%3E% %3Ccircle  stroke='#{str-replace(quote(#{$color1}),'#','%23')}' fill='#{str-replace(quote(#{$color1}),'#','%23')}' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
}

// color1: main
// color2: tint
@mixin selected-end($color1: $blue5, $color2: $blue5Tint2){
    background-color: transparent;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg  viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg' %3E %3Crect x='0' y='0' width='16' height='32' stroke-width='1' stroke='#{str-replace(quote(#{$color2}),'#','%23')}' fill='#{str-replace(quote(#{$color2}),'#','%23')}'/%3E% %3Ccircle  stroke='#{str-replace(quote(#{$color1}),'#','%23')}' fill='#{str-replace(quote(#{$color1}),'#','%23')}' stroke-width='1' cx='16' cy='16' r='15'/%3E  %3C/svg%3E");
} 
  