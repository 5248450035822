// -----------------------------
// CB Accordion
// -----------------------------

@import "apricot-variable.scss";

@import "../mixins/font.scss";
@import "../mixins/accordion.scss";
@import "../mixins/breakpoint.scss";

.cb-accordion {
  //  Controls
  .cb-accordion-controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 8px;

    .cb-accordion-buttons {
      margin-left: auto;
      padding-left: 24px;

      .cb-btn {
        padding: 0;
        border: 0;
        border-radius: 0;

        &:first-of-type {
          padding-right: 24px;
          border-right: 1px solid $gray4;
          margin-right: 24px;
        }

        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
        }
      }
    }

    .cb-accordion-title {
      h2,
      h3,
      h4 {
        // margin-right: 24px;
        @include roboto();
        @include media-breakpoint-up("sm", $grid-breakpoints) {
          font-size: 1.75rem; //28
          line-height: 1.1428571428571428em; //32
        }

        @include media-breakpoint-up("lg", $grid-breakpoints) {
          font-size: 2rem; //32
          line-height: 1.25em; //40
        }
      }
    }
  }

  // Panels
  .cb-accordion-panels {
    border-top: 1px solid $gray4;
    display: flex;
    flex-direction: column;

    .cb-accordion-container {
      display: flex;
      flex-direction: column;

      .cb-accordion-heading {
        padding: 24px;
        text-decoration: none;
        color: $font-color-base;
        border-top: 1px solid $gray4;
        transition: all $transition-time-s ease-in-out;

        .cb-accordion-heading-title {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            @include roboto();
            font-size: 1rem;
            line-height: 1.5em;
            margin-right: 16px;
          }
        }

        .cb-accordion-heading-content {
          margin-right: 32px;
        }

        &.cb-active {
          .cb-accordion-icon {
            @include accordion-active-trigger();
          }
        }

        &:hover {
          cursor: pointer;
          color: $font-color-base;

          .cb-accordion-heading-title {
            text-decoration: underline;
          }
        }
        &:not(.cb-active):hover {
          background-color: $gray5;
        }
      }

      &:first-of-type {
        .cb-accordion-heading {
          border-top: 0;
        }
      }

      .cb-accordion-panel {
        height: 0;
        overflow: hidden;
        padding: 0;
        visibility: hidden;

        .cb-accordion-panel-content {
          padding: 24px;
        }

        &.transition {
          transition: height $transition-time-l ease;

          @media (prefers-reduced-motion: reduce) {
            transition: none;
          }
        }

        background: $white;
        background: linear-gradient(180deg, rgba($gray5, 1) 0%, rgba($white, 1) 20%);

        &.cb-in {
          height: auto;
          overflow-y: auto;
          visibility: visible;
        }
        // nested
        .cb-accordion {
          .cb-accordion-panels {
            border-top: 0;
            .cb-accordion-heading {
              background-color: $gray5;
              border-top: 1px solid $gray3;
            }
          }
        }
      }
      &.cb-accordion-panel-overflow {
        .cb-accordion-panel {
          overflow: unset!important;
          &.cb-in {
            overflow-y: unset!important;
          }
        }
      }
    }
  }
}

// Triggers
.cb-accordion-trigger {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;

  .cb-accordion-icon {
    width: 14px;
    height: 14px;

    .line {
      top: 6px;
    }
  }

  &.cb-active {
    @include accordion-active-trigger();
  }

  &:hover {
    .cb-accordion-icon {
      .line {
        background: $link-hover-color;
      }
    }
  }

  &:active {
    .cb-accordion-icon {
      .line {
        background: $link-active-color;
      }
    }
  }

  &.cb-link-blue {
    text-decoration: none;

    &:hover,
    &.cb-hover {
      text-decoration: underline;
    }

    &:not(.cb-btn):visited,
    &:not(.cb-btn).cb-visited {
      color: $blue5;
    }

    .cb-accordion-icon {
      .line {
        background: $blue5;
      }
    }

    &:hover {
      .cb-accordion-icon {
        .line {
          background: $blue5;
        }
      }
    }

    &:active,
    &.cb-active {
      .cb-accordion-icon {
        .line {
          background: $blue5;
        }
      }
    }
  }
}

.cb-accordion-trigger-panel {
  height: 0;
  overflow: hidden;
  padding: 0;
  transition: margin $transition-time-l ease;
  visibility: hidden;

  &.transition {
    transition: height $transition-time-l ease;
  }

  &.cb-in {
    height: auto;
    border-top: 1px solid $gray4;
    border-bottom: 1px solid $gray4;
    margin-bottom: 24px;
    visibility: visible;
  }

  .cb-accordion-panel-content {
    padding: 24px 0;
  }


  &.cb-accordion-panel-overflow {
    overflow: unset!important;
  }
}

// +/- icons
.cb-accordion-icon {
  position: relative;
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  .line {
    position: absolute;
    height: 2px;
    width: 100%;
    background: $black1;
    // transition: all cubic-bezier(1, 0, 0, 1) $transition-time-l;
    top: 7px; // based on 16px
    transition: all $transition-time-s;

    &.top {
      transform: rotate(90deg);
    }

    &.bottom {
      transform: rotate(180deg);
    }
  }

  &.cb-active {
    @include accordion-active-trigger();
  }
}

.cb-accordion {
  &.cb-accordion-simple {
    .cb-accordion-panels {
      .cb-accordion-container {
        .cb-accordion-heading {
          .cb-accordion-heading-title {
            align-items: flex-start;
            .cb-accordion-icon {
              margin-top: 4px;
              margin-left: 16px;
            }
          }
          &.cb-accordion-left-icon {
            .cb-accordion-heading-title {
              justify-content: unset;
              .cb-accordion-icon {
                margin-right: 24px;
                margin-left: 0;
              }
            }
          }
          &.cb-disabled {
            background: $gray5;
            .cb-accordion-icon {
              span {
                display: none;
              }
              font-family: CB Icons;
              position: relative;
              display: inline-block;
              width: 1em;
              height: 1em;
              font-size: 1.2rem;
              line-height: 1em;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              &::before,
              &::after {
                position: absolute;
                top: -50%;
                left: -50%;
                font-size: 2em;
              }
              &::before {
                content: "\e970";
              }
            }
            &:hover {
              cursor: not-allowed!important;
              .cb-accordion-heading-title {
                text-decoration: none!important;
              }
            }
          }
        }
      }
    }

    // Expandable
    &.cb-accordion-expandable {
      .cb-accordion-trigger {
        justify-content: start;
        cursor: pointer;
        max-width: fit-content;
        + .cb-accordion-panel {
          &.cb-in {
            margin-top: 24px;
          }
        }
      }
      .cb-accordion-panels{
        border-top: 0;
      }

      .cb-accordion-panel {
        background: $white!important;
        &.cb-in {
          border-top: 1px solid $gray4;
          border-bottom: 1px solid $gray4;
          + .cb-accordion-trigger {
            margin-top: 24px;
          }
          .cb-accordion-panel-content {
            padding: 24px 0;
          }
        }
      }
    }
  }
}
