// -----------------------------
// CB Slider
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";

@mixin horizontalSlider() {
  flex-direction: column;
  align-items: flex-start !important;
  .cb-input-container {
    display: flex;
    .cb-input-slider {
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;

      label {
        margin-left: 0;
        margin-top: 8px;
      }

      + .cb-input-slider {
        margin-left: 8px;
      }
    }
  }
}

.cb-slider,
.cb-slider * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box;
}
.cb-slider {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: $gray4;
  .cb-slider-base,
  .cb-slider-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  .cb-slider-connects {
    overflow: hidden;
    z-index: 0;
    border-radius: 2px;
    .cb-slider-connect,
    .cb-slider-origin {
      will-change: transform;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      transform-origin: 0 0;
      transform-style: flat;
    }
    .cb-slider-origin {
      height: 0;
      left: 0;
      right: auto;
    }
    .cb-slider-connect {
      background: $blue5;
    }
  }
}

.cb-slider-handle {
  backface-visibility: hidden;
  position: absolute;

  width: 8px;
  height: 8px;
  right: -5px;
  top: -8px;
  transition: box-shadow 0.3s ease-out;

  border-radius: 50%;
  background: $blue5;
  border: 2px solid $blue5;
  box-sizing: content-box;
  cursor: default;

  &:hover {
    background: $white;
    cursor: pointer;
  }
  &.cb-slider-active,
  &:focus {
    width: 18px;
    height: 18px;
    right: -10px;
    top: -14px;
    cursor: pointer;
    outline: none;
    background: $black1;
    border: 2px solid $white;
    box-shadow: 0 0 0 1px $blue5;
    &:after {
      display: block;
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      right: 3px;
      top: 3px;
      border-radius: 50%;
      background: $white;
      border: 2px solid $white;
    }
  }
  &.cb-slider-active {
    &:after {
      display: none;
    }
    background: $blue5;
    border: 2px solid $white;
    box-shadow: 0 0 0 1px $blue5;
  }
}

.cb-slider-touch-area {
  height: 24px;
  width: 24px;
  margin-top: -8px;
  margin-left: -8px;
}
.cb-slider-state-tap .cb-slider-connect,
.cb-slider-state-tap .cb-slider-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

/* Base;
 *
 */
.cb-slider-pips,
.cb-slider-pips * {
  box-sizing: border-box;
}
.cb-slider-pips {
  position: absolute;
  color: $gray2;
}

/* Values;
  *
  */
.cb-slider-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
  color: $gray1;
  line-height: 32px;
}

/* Markings;
  *
  */
.cb-slider-marker {
  position: absolute;
  background: $gray2;
}
.cb-slider-marker-large {
  background: $gray1;
}

.cb-slider-pips {
  height: 24px;
  top: 0;
  left: 0;
  width: 100%;
}

.cb-slider-value {
  transform: translate(-50%, 50%);
}

.cb-slider-marker.cb-slider-marker {
  margin-left: -1px;
  width: 1px;
  height: 4px;
}
.cb-slider-marker.cb-slider-marker-large {
  height: 12px;
}

// -----Tooltip
.cb-slider-tooltip {
  display: none;
  position: absolute;

  background: $tooltip-bg;
  color: $tooltip-color;
  border: 1px solid $tooltip-border-color;
  border-radius: $tooltip-border-radius;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: $zindex-tooltip;

  font-size: $tooltip-font-size;
  padding: 0 6px;

  white-space: nowrap;

  transform: translate(-50%, 0);
  left: 50%;
  top: -42px;
}

.cb-slider-handle {
  &:not([aria-disabled]) {
    &.cb-slider-active,
    &:focus {
      .cb-slider-tooltip {
        display: block;
        top: -36px;
      }
    }
  }
}

.cb-slider-sticky-tooltip {
  .cb-slider-tooltip {
    display: block;
  }
}

// With inputs/span
.cb-slider-container {
  display: flex;
  width: 100%;
  &.cb-slider-single {
    align-items: center;
  }
  &.cb-slider-range {
    align-items: flex-end;
  }

  .cb-input-container {
    width: 20%;
    .cb-input-slider {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      label {
        margin-left: 8px;

        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
      }
      input {
        min-width: 64px;
        background-color: $blue5Tint2;
        font-size: $font-size-sm;
        line-height: 1.7142857142857142em;
        padding: 4px 11px 4px 12px;

        line-height: 1.5em;

        border: 1px solid $blue5Tint2;
        border-radius: $border-radius;
        &:disabled,
        &:read-only {
          color: $black1;
          opacity: 1;
          cursor: not-allowed !important;
          border: 1px solid $gray4 !important;
          background: $gray5 !important;
          cursor: not-allowed;
          -webkit-text-fill-color: $gray1; /* Override iOS / Android font color change */
          -webkit-opacity: 1; /* Override iOS opacity change affecting text & background color */
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        &:read-only:not(:disabled) {
          cursor: default !important;
          border-color: $gray5 !important;
        }
      }
      + .cb-input-slider {
        margin-top: 8px;
      }
    }
  }
  &.cb-slider-stacked {
    @include horizontalSlider();
  }
}
@include media-breakpoint-only("xs", $grid-breakpoints) {
  .cb-slider-container {
    @include horizontalSlider();
  }
}

.cb-slider-min,
.cb-slider-max {
  display: inline-flex;
  // background-color: $blue5Tint2;
  font-size: $font-size-sm;
  line-height: 1.7142857142857142em;
  padding: 7px 15px;

  line-height: 1.5em;
  // border: 1px solid $blue5Tint2;
  // border-radius: $border-radius;
}

/* Disabled state; */
[disabled],
[aria-disabled] {
  &.cb-slider,
  .cb-slider,
  &.cb-slider-handle,
  .cb-slider-handle {
    cursor: not-allowed;
  }
  &.cb-slider,
  .cb-slider {
    .cb-slider-connect {
      background: $gray5;
    }
    .cb-slider-handle {
      &,
      &:hover,
      &:focus,
      &.cb-slider-active {
        width: 8px !important;
        height: 8px !important;
        right: -5px !important;
        top: -8px !important;
        background: $gray5 !important;
        border-color: $gray4 !important;
        box-shadow: none !important;
        &:after {
          display: none;
        }
      }

      &.cb-slider-active,
      &:focus {
        .cb-slider-tooltip {
          top: -42px;
        }
      }
    }
  }

  &.cb-slider-handle,
  .cb-slider-handle {
    &,
    &:hover,
    &:focus,
    &.cb-slider-active {
      width: 8px !important;
      height: 8px !important;
      right: -5px !important;
      top: -8px !important;
      background: $gray5 !important;
      border-color: $gray4 !important;
      box-shadow: none !important;
      &:after {
        display: none;
      }
    }
  }
  .cb-slider-tooltip {
    background-color: $gray4;
    color: $black1;
    border: 1px solid $tooltip-border-color;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  }
}

.cb-slider {
  &.cb-slider-black {
    .cb-slider-connects {
      .cb-slider-connect {
        background: $black1;
      }
    }
    .cb-slider-handle {
      background: $black1;
      border: 2px solid $black1;
      &:hover {
        background: $white;
        cursor: pointer;
      }
      &.cb-slider-active,
      &:focus {
        background: $blue5;
        box-shadow: 0 0 0 1px $black1;
        border-color: $white;
      }
      &.cb-slider-active {
        background: $black1;
        box-shadow: 0 0 0 1px $blue5;
      }
    }
  }
}
