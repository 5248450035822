// -----------------------------
// CB Checkable Menus
// -----------------------------

@import "apricot-variable.scss";

.cb-menu-item-radio {
  padding: 0;
  list-style: none;
  button {
    background: transparent;
    border: 0;

    position: relative;
    padding-left: 32px;
    text-align: left;
    &:before {
      content: "";
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      left: 0px;
      top: 0px;

      height: 24px;
      width: 24px;
      min-width: 24px;
      background-color: $white;
      transition: all 0.2 ease-out;
      border: 1px solid $black1;
      border-radius: 50%;
      outline: none;
    }
    &::after {
      position: absolute;
      display: block;
      box-sizing: border-box;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 50%;
      background-color: $blue5;
      border: 0 solid $blue5;
      opacity: 1;
      transition: all $transition-time ease-out;
    }

    &[aria-checked="true"] {
      &::before {
        border-color: $blue5;
        border-width: 2px;
        background-color: $white;
      }
      &::after {
        width: 0;
        height: 0;
        border: 7px solid $blue5;
        top: 5px;
        left: 5px;
      }
    }

    &:hover {
      &::before {
        background-color: $gray5;
        border: 1px solid $blue5;
      }
    }
    &:disabled,
    &.cb-disabled {
      &::before {
        cursor: not-allowed !important;

        border: 1px solid $gray4 !important;
        background: $gray5 !important;
      }
      &::after {
        border-color: $gray4;
      }
      &[aria-checked="true"] {
        &::after {
          background: $gray4;
          border-color: $gray4;
        }
      }
    }
  }
  
  .cb-label-left {
    padding: 8px;
    background: transparent;
    margin-left: -8px;
    padding-right: 16px;
    button {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      text-align: left;
      &:before {
        left: auto;
        right: 0px;
        top: 0px;
      }
      &::after {
        left: auto;
        right: 12px;
      }
      &[aria-checked="true"] {
        &::after {
          left: auto;
          right: 5px;
        }
      }
    }
    &:hover {
      cursor: pointer;
      border-radius: 4px;
      background: $gray5;
      transition: all $transition-time-s ease-in-out;
    }
  }
}


// ------------------------------------ FOCUS

[data-cb-input-m="keyboard"] {
  // ------------------------------------ RADIO BUTTON
  .cb-menu-item-radio {
    button {
      &:focus {
        outline: none;
        &::before {
          box-shadow: 0 0 0 2px $blue5;
        }
      }
    }
  }
}
