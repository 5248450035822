// -----------------------------
// CB Floating Label
// -----------------------------

.cb-floating-label {
  position: relative;

  label {
    z-index: 2;
    position: absolute;

    left: 16px;
    top: 12px;

    line-height: 1.5em;
    background-color: $white;

    transition-property: top, font-size;
    transition-duration: 0.1s;
    transition-timing-function: linear;

    &.cb-required {
      &::after {
        background-color: $white;
        padding: 0 2px 0 4px;
        margin-left: 2px;
      }
    }

    &.cb-value-fl,
    &.cb-focus-fl,
    &.cb-fill-fl {
      color: $gray1;
      font-size: $font-size-xsmall;
      line-height: $line-height-small;
      font-weight: 500;
      background-color: $white;

      padding: 0 2px;
      left: 14px;
      top: -10px;
    }

    &.cb-focus-fl {
      color: $input-focus;
      font-weight: 500;
    }
  }

  .cb-input-icon-left {
    label {
      left: 39px;

      &.cb-value-fl,
      &.cb-focus-fl {
        left: 14px;
      }
    }

    .cb-glyph,
    .cb-icon {
      top: 12px;
    }
  }

  .cb-input-icon-right {
    .cb-glyph,
    .cb-icon {
      top: 12px;
    }
  }

  // ------------------------------------ CONDENSED
  &.cb-input-condensed,
  &.cb-condensed {
    label {
      top: 9px;

      &.cb-value-fl,
      &.cb-focus-fl {
        left: 14px;
        top: -10px;
      }
    }

    .cb-input-icon-left {
      .cb-glyph,
      .cb-icon {
        top: 9px;
        left: 16px;
      }
    }

    .cb-input-icon-right {
      .cb-glyph,
      .cb-icon {
        top: 9px;
      }
    }
  }

  // ------------------------------------ BUTTON INPUT
  &.cb-btn-input {
    .cb-btn {
      top: 8px;
    }
  }

  // autofill
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    animation-name: onAutoFillStart;
    transition: font-size 3000s ease-in-out 0s;
  }
}

.cb-floating-label {
  &.cb-not-active {
    label {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  // &.cb-input-date {
  //   label {
  //     opacity: 0;
  //     transition: none;
  //     &.cb-focus-fl {
  //       opacity: 1;
  //       transition: opacity $transition-time-s ease-in-out;
  //     }
  //   }
  // }
}

@keyframes onAutoFillStart {
  from {
    font-size: inherit;
  }
  to {
    font-size: inherit;
  }
}

// ------------------------------------ CUSTOM COLOR

.cb-floating-label {
  label {
    &.cb-dark-bg {
      background: transparent !important;
      &.cb-value-fl,
      &.cb-focus-fl,
      &.cb-fill-fl {
        color: $white;
        top: -20px;
      }
    }
    &.cb-light-bg {
      background: transparent !important;
      &.cb-value-fl,
      &.cb-focus-fl,
      &.cb-fill-fl {
        color: $black1;
        top: -20px;
      }
    }
    &.cb-required {
      &::after {
        background-color: transparent;
      }
    }
  }
}

// ------------------------------------ SAFARI Issue
[class*="safari"] {
  .cb-floating-label {
    label {
      top: 13px;
      &.cb-value-fl,
      &.cb-focus-fl,
      &.cb-fill-fl {
        top: -10px;
      }
    }
    &.cb-select {
      label {
        top: 14px;
        &.cb-value-fl,
        &.cb-focus-fl {
          top: -10px;
        }
      }
    }
    &.cb-input-condensed,
    &.cb-condensed {
      label {
        top: 10px;

        &.cb-value-fl,
        &.cb-focus-fl,
        &.cb-fill-fl {
          top: -10px;
        }
      }
    }
  }
}
