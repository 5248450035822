// -----------------------------
// CB Anchor Menu
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";

.cb-anchor-menu {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 24px;
  top: 96px;
  z-index: 2;
  @include media-breakpoint-only("xs", $grid-breakpoints) {
    top: 72px;
  }

  .cb-menu-list {
    position: absolute;
    background-color: $white;
    top: 49px;
    width: 240px;
    right: -268px;
    transition: right $transition-time-l ease;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    max-height: calc(100vh - 192px);

    a {
      &.cb-menu-link {
        &:hover {
          cursor: pointer !important;
        }
      }
    }
  }

  .cb-anchor-menu-btn {
    align-self: flex-end;
    &:focus {
      background-color: $white;
    }

    &.cb-btn-floating {
      border-radius: 8px;
    }

    .cb-menu-icon {
      width: 16px;
      height: 16px;
      position: relative;
      transition: 0.5s ease-in-out;
      display: block;
      transform: rotate(0);

      span {
        position: absolute;
        height: 2px;
        width: 100%;
        background: $black1;
        border-radius: 0;
        opacity: 1;
        left: 0;
        transition: $transition-time-s ease-in-out;

        &:nth-child(1) {
          top: 1px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 7px;
        }

        &:nth-child(4) {
          top: 13px;
        }
      }
      &:focus {
        border: 0;
        outline: 0;
      }
    }
  }

  &.cb-open {
    .cb-anchor-menu-btn {
      span,
      a {
        span {
          &:nth-child(1),
          &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
          }

          &:nth-child(2) {
            transform: rotate(45deg);
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }

    .cb-menu-list {
      right: 0;
    }
  }

  &.cb-anchor-bottom {
    top: auto;
    bottom: 96px;
    @include media-breakpoint-only("xs", $grid-breakpoints) {
      bottom: 72px;
    }
    .cb-menu-list {
      top: auto;
      bottom: 49px;
      max-height: calc(100vh - 240px);
    }
  }
}
