// -----------------------------
// CB Toasts
// -----------------------------
@use "sass:math";
@import "apricot-variable.scss";
@import "../mixins/breakpoint.scss";
@import "../mixins/link.scss";
@import "../mixins/font.scss";
@import "../mixins/toast.scss";

.cb-toast {
  &[aria-hidden="true"] {
    display: none;
  }
  // new toasts slide in from the top
  @include react-transition-phase(
    "cb-toast",
    "enter",
    $enter-translate,
    $duration: $toast-transition-duration * 3,
    $easing: $toast-transition-ease,
    $before: "&"
  );
  // previous sibling gets pushed down
  @include react-transition-phase(
    "cb-toast",
    "enter",
    $enter-translate-sibling,
    $duration: $toast-transition-duration * 3,
    $easing: $toast-transition-ease,
    $before: "&",
    $after: "~ &"
  );
  // leaving toasts simply fade away
  @include react-transition-phase(
    "cb-toast",
    "exit",
    $leave-translate,
    $easing: $toast-transition-ease,
    $duration: $toast-transition-duration * 3,
    $before: "&"
  );
  // younger siblings of leaving toasts wait a moment before moving to fill gap
  @include react-transition-phase(
    "cb-toast",
    "exit",
    $enter-translate-sibling,
    $easing: $toast-transition-ease,
    $delay: math.div($toast-transition-duration, 2),
    $before: "&",
    $after: "~ &"
  );

  position: relative !important;
  pointer-events: all;
  background: $white;
  align-items: flex-start;
  display: flex;
  border: 1px solid $black1;
  padding: 0;
  border-radius: 8px;
  width: 498px;
  max-width: 498px;
  margin-top: $toast-space;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  line-height: 1.43em;
  box-sizing: content-box;

  @include media-breakpoint-only("xs", $grid-breakpoints) {
    max-width: calc(100% - 48px);
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
  }

  // based on btn height
  .cb-toast-msg,
  .cb-toast-action {
    margin: 13px 0 13px 16px;
  }

  .cb-glyph,
  .cb-icon {
    &:not(.cb-x-mark) {
      font-size: 1rem;
      line-height: 18px;
      margin: 14px 0 14px 16px;
    }
    &.cb-check,
    &.cb-check-fill {
      color: $green3;
    }
    &.cb-exclamation-circle,
    &.cb-exclamation-fill {
      color: $red1;
    }
  }
  .cb-toast-msg {
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 16px;

    @include media-breakpoint-only("xs", $grid-breakpoints) {
      text-overflow: unset;
      -webkit-line-clamp: unset;
    }
  }
  .cb-btn-close {
    min-width: 32px;
    margin: 7px 8px 7px 0;
    font-size: 14px !important;
    margin-left: auto!important;
  }
  a {
    &.cb-toast-action {
      @include roboto-medium();
      @include black-link();
      text-transform: uppercase;
      margin-left: auto!important;
      margin-right: 16px;
    }
  }
  .cb-toast-action {
    + .cb-btn-close {
      margin-left: 0!important;
    }
  }

  &.cb-toast-alternative,
  &.cb-toast-success,
  &.cb-toast-warning {
    color: $white;
    a {
      @include white-link();
    }
    .cb-glyph,
    .cb-icon {
      &.cb-check,
      &.cb-check-fill,
      &.cb-exclamation-circle,
      &.cb-exclamation-fill {
        color: $white!important;
      }
    }

    .cb-btn-close {
      color: $white;
      background: transparent;
      &:focus {
        color: $black1 !important;
        background-color: $gray5 !important;
      }
    }
  }

  &.cb-toast-alternative {
    background: $black1;
    border-color: $gray1;
    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          background-color: mix(white, $black1, 20%);
          box-shadow: 0 0 0 2px $white;
          color: $white;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &.cb-toast-success {
    background: $green3;
    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          background-color: mix(black, $green3, 20%);
          color: $white;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  &.cb-toast-warning {
    background: $red1;
    .cb-btn-close {
      &.cb-btn-greyscale {
        &:hover {
          background-color: mix(black, $red1, 20%);
          color: $white;
        }
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.cb-toast-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: auto;
  // container will not block clicks on elements behind it
  pointer-events: none;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: $zindex-toast;

  &.cb-toast-container-bottom {
    bottom: 0;
    flex-direction: column-reverse;
    top: auto;
    .cb-toast {
      // minimal diff in react-transition styles so we can avoid calling those mixins again
      &.cb-toast-enter:not(.cb-toast-enter-active),
      &.cb-toast-enter:not(.cb-toast-enter-active) ~ .cb-toast,
      &.cb-toast-appear:not(.cb-toast-appear-active),
      &.cb-toast-appear:not(.cb-toast-appear-active) ~ .cb-toast,
      &.cb-toast-exit-active ~ .cb-toast,
      &.cb-toast-leave-active ~ .cb-toast {
        transform: translateY($toast-space + $toast-height);
      }
    }
  }
}
