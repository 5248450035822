// -----------------------------
// CB Menu Bar
// -----------------------------

@import "apricot-variable.scss";
@import "../mixins/color.scss";

.cb-menu-bar {
  &.cb-menu-bar-right {
    @include box-shadow-left();
    min-width: 240px;
    position: fixed;
    right: 0;
    min-height: 100vh;
    z-index: 1023;
  }

  &.cb-menu-bar-left {
    @include box-shadow-right();
    min-width: 240px;
    position: fixed;
    left: 0;
    min-height: 100vh;
    z-index: 1023;
  }

  &.cb-menu-bar-top {
    padding-bottom: 24px;
    padding-top: 24px;
    position: fixed;
    top: 0;
    @include box-shadow-bottom();
    z-index: 1024;
  }

  &.cb-menu-bar-bottom {
    padding-bottom: 24px;
    padding-top: 24px;
    position: fixed;
    bottom: 0;
    @include box-shadow-top();
    z-index: 1025;
  }
}