// -----------------------------
// CB Print Mixins
// -----------------------------

// ------------- General settings
@mixin print-page() {
  *,
  *::before,
  *::after {
    background: transparent !important;
    color: $black1 !important; // Black prints faster: h5bp.com/s
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a {
    &:not(.btn) {
      text-decoration: underline;

      &[href]::after {
        content: " (" attr(href) ")";
      }

      // Don't show links that are fragment identifiers,
      // or use the `javascript:` pseudo protocol
      &[href^="#"]::after,
      &[href^="javascript:"]::after {
        content: "";
      }
    }

    &.cb-no-print-href::after {
      content: "" !important;
    }
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
    word-break: break-all;
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: $border-width solid $gray2;
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    max-width: 100%;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h1,
  h2,
  h3 {
    page-break-after: avoid;
  }

  select {
    background: $white !important;
  }

  // Specify a size and min-width to make printing closer across browsers.

  @page {
    size: $print-page-size;
  }

  body {
    min-width: $print-body-min-width !important;
  }
}

// ------------- Treatment for Apricot Components
@mixin print-apricot() {
  // ------------------------------------ ATOMS
  .container {
    min-width: $print-body-min-width !important;
    max-width: $print-body-min-width !important;
  }

  // Buttons
  .cb-btn {
    border: 1px solid $black1 !important;
    border-color: $black1 !important;
    background-color: $white !important;
    color: $black1;

    .cb-btn-pulse-dots {
      display: none;
    }
  }

  .cb-anchor-menu,
  .cb-back-to-top {
    display: none !important;
  }

  // Borders
  [class^="cb-border"] {
    &:not(.cb-border-style) {
      border-width: 1px !important;
    }

    border-color: $black1 !important;

    &.cb-border-style {
      &[class^="cb-border-right"] {
        border-right-width: 1px !important;
      }

      &[class^="cb-border-left"] {
        border-left-width: 1px !important;
      }

      &[class^="cb-border-top"] {
        border-top-width: 1px !important;
      }

      &[class^="cb-border-bottom"] {
        border-bottom-width: 1px !important;
      }
    }
  }

  .cb-text-list-feature {
    border-color: $black !important;

    li {
      border-color: $black !important;
    }
  }

  /// ------------------------------------ MOLECULES

  // Accordion
  .cb-accordion {
    .cb-accordion-controls {
      .cb-accordion-buttons {
        @include no();
      }
    }

    .cb-accordion-panels {
      border-color: $black !important;

      .cb-accordion-container {
        .cb-accordion-panel {
          height: auto !important;

          .cb-accordion-panel-content {
            padding: 24px;
          }
        }

        .cb-accordion-heading {
          border-color: $black !important;
        }
      }
    }
  }

  .cb-accordion-trigger-panel {
    height: auto !important;
    border-color: $black !important;

    .cb-accordion-panel-content {
      padding: 24px;
    }
  }

  // Cards
  .cb-card {
    border: 1px solid $black1;
    box-shadow: none !important;

    a:not(.btn)[href] {
      &:after {
        content: "" !important;
      }
    }
  }

  .cb-card-list-vertical {
    .cb-card {
      border-top: 1px solid $border-color;
    }
  }

  // Notification
  .cb-notification {
    border-color: $black1 !important;

    .cb-notification-header {
      .cb-btn {
        @include no();
      }
    }
  }

  // Forms
  label {
    background-color: $white !important;
  }

  .cb-input {
    border-color: $black1 !important;

    input,
    textarea {
      border-color: $black1 !important;
    }
  }

  .cb-select {
    @include select-print($black1, "-bottom");

    &.cb-input-outlined {
      @include select-print($black1, "");
    }
  }

  .cb-select[multiple] {
    border-color: $black !important;
  }

  .cb-validation-error,
  .cb-validation-success {
    &.cb-input {
      input,
      textarea {
        border-color: $black1 !important;
      }
    }

    &.cb-checkbox {
      input[type="checkbox"] + span {
        border-color: $black1 !important;
      }
    }

    .cb-checkbox {
      input[type="checkbox"] + span {
        border-color: $black1 !important;
      }
    }

    &.cb-radio {
      input[type="radio"] + span {
        border-color: $black1 !important;
      }
    }

    .cb-radio {
      input[type="radio"] + span {
        border-color: $black1 !important;
      }
    }
  }

  // Radio
  .cb-radio {
    input[type="radio"]:checked + span::after {
      border-color: $black1 !important;
    }
    input[type="radio"] {
      &:checked {
        + span {
          border-color: $black1 !important;
        }
      }

      &:hover:not(:checked),
      &:focus:not(:checked) {
        + span {
          border: 1px solid $black1 !important;
        }
      }
    }
  }

  // checkbox
  .cb-checkbox {
    input[type="checkbox"]:checked + span::after {
      background-color: transparent;
      border-color: $black1 !important;
    }
    input[type="checkbox"] {
      &:checked {
        + span {
          border-color: $black1 !important;
        }
      }

      &:hover:not(:checked),
      &:focus:not(:checked) {
        + span {
          border: 1px solid $black1;
        }
      }
    }
  }

  // toggles buttons
  .cb-toggles-btn {
    input[type="radio"] {
      @include no();

      &:checked,
      &:active,
      &.cb-checked,
      &.cb-active {
        & + span:not(.cb-glyph),
        & + span:not(.cb-icon) {
          color: $black1 !important;

          + .cb-glyph,
          + .cb-icon {
            &::before {
              color: $black1 !important;
            }
          }
        }
      }
    }

    label {
      input[type="radio"] + span:not(.cb-glyph) {
        border-color: $black !important;
      }
    }
  }

  // toggles switch
  .cb-toggle-switch {
    .cb-switch {
      @include no();
    }
  }

  // Menus
  .cb-menu-list {
    &.cb-menu-list-vertical {
      &.cb-menu-list-separated ul li + li a {
        border-color: $black;
      }
    }
  }

  .cb-date-time-tags {
    p:first-of-type::after {
      background-color: $black !important;
    }
  }

  // Pagination
  .cb-pagination {
    & > li {
      .cb-btn-square {
        text-decoration: none !important;

        &.cb-active {
          background-color: $black1 !important;
          border-color: $black1 !important;
          color: $white !important;
        }
      }
    }
  }

  // Tables
  .cb-table {
    page-break-inside: auto !important;

    position: relative !important;
    width: 100%;

    tr {
      page-break-inside: auto !important;
    }

    th,
    td {
      border-color: $black1 !important;
      page-break-inside: avoid !important;
    }
  }

  // mobile friendly Table
  .cb-table-mobile {
    .cb-last-row {
      td,
      th {
        border-bottom-width: 2px;
      }
      &:last-of-type {
        td,
        th {
          border-bottom-width: 1px;
        }
      }
    }
  }
  // Print friendly Table
  .cb-table-print {
    thead {
      display: none;
    }
    tbody {
      tr {
        td,
        th {
          border-top: 0;
          display: flex;
          width: 100%;
          position: relative;
          &::before {
            content: attr(data-cb-label);
            width: 50%;
            min-width: 50%;
          }
          &::after {
            content: "";
            border-right: 1px solid $black1;
            width: 1px;
            height: 100%;
            display: block;
            position: absolute;
            left: calc(50% - 8px);
            top: 0;
          }
          &:first-child {
            padding-top: 24px;
            border-top: 2px solid $black1;
          }
          &:last-child {
            padding-bottom: 24px;
          }
        }
        &:first-of-type {
          td,
          th {
            &:first-child {
              padding-top: 11px;
              border-top: $table-border-width solid $black1;
            }
          }
        }
      }
    }
  }

  .cb-scrollbar-container {
    overflow: unset !important;
    border: 0 !important;
    width: 100%;

    .cb-table-responsive {
      width: 100% !important;
      height: auto !important;
      overflow: unset !important;

      .cb-scrollbar {
        display: none;
      }

      .cb-table {
        width: 100% !important;
        // page-break-inside: auto;
        position: relative !important;
      }
    }
  }

  // Event Timeline
  .cb-event-timeline {
    margin-left: 0 !important;
    .cb-gutterh-24 {
      margin-left: 0;
      li,
      div {
        margin-left: 0;
      }
    }
    > ol {
      flex-wrap: wrap !important;
      padding: 0 !important;
      flex-direction: row !important;
      padding-top: 72px;
      > li {
        width: calc(50% - 24px) !important;
        max-width: calc(50% - 24px) !important;
        break-inside: avoid !important;
        page-break-inside: avoid !important;
        position: relative;
        flex-grow: 1 !important;
        flex-basis: auto !important;
        margin-top: 64px !important;
        margin-bottom: 24px;
        &::before {
          border-top: 2px solid $black;
          position: absolute;
          display: block;
          content: "";
          height: 2px !important;
          width: calc(100% + 28px) !important;
          top: -24px !important;
          left: 27px !important;
        }
        &::after {
          position: absolute;
          display: block;
          content: "";
          width: 1rem;
          height: 1rem;
          border-radius: 100%;
          border: 8px solid $black;
          top: -31px !important;
          left: 27px !important;
        }

        &:nth-child(even) {
          margin-left: 24px;
          &::before {
            width: calc(100% - 43px) !important;
          }
          .cb-card-header {
            position: relative;
            &::after {
              font-family: CB Glyphs;
              font-style: normal;
              font-weight: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              content: "\e940";
              position: absolute;
              right: -15px;
              top: -55px;
              width: 16px;
              height: 16px;
              display: block;
            }
          }
        }
        &:last-child {
          &::before {
            width: 0 !important;
          }
          &:nth-child(odd) {
            &::after {
              border: 0;
            }
          }
          .cb-card-header {
            position: relative;
            &::after {
              content: "";
            }
          }
        }
      }
    }

    .row {
      flex-wrap: wrap !important;
      padding: 0 !important;
      flex-direction: row !important;
      padding-top: 72px;
      div[class*="col-"] {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        margin-top: 64px !important;
        .cb-card {
          width: 100%;
          max-width: 100%;
          break-inside: avoid !important;
          page-break-inside: avoid !important;
          position: relative;
          flex-grow: 1 !important;
          flex-basis: auto !important;
          margin-top: 24px !important;
          margin-bottom: 24px;
          &::before {
            border-top: 2px solid $black;
            position: absolute;
            display: block;
            content: "";
            height: 2px !important;
            width: calc(100% + 28px) !important;
            top: -24px !important;
            left: 27px !important;
          }
          &::after {
            position: absolute;
            display: block;
            content: "";
            width: 1rem;
            height: 1rem;
            border-radius: 100%;
            border: 8px solid $black;
            top: -31px !important;
            left: 24px !important;
          }
        }

        &:nth-child(even) {
          // margin-left: 24px;
          .cb-card {
            &::before {
              width: calc(100% - 39px) !important;
            }
            .cb-card-header {
              position: relative;
              &::after {
                font-family: CB Glyphs;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                // content: "\e916";
                content: "\e940";
                position: absolute;
                right: -19px;
                top: -55px;
                width: 16px;
                height: 16px;
                display: block;
              }
            }
          }
        }
        &:last-child {
          &:nth-child(odd) {
            .cb-card {
              &::after {
                border: 0;
              }
            }
          }
          .cb-card {
            &::before {
              width: 0 !important;
            }
            .cb-card-header {
              &::after {
                content: "";
              }
            }
          }
        }
      }
    }
    .cb-event-year-stamp {
      .cb-event-year {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 32px !important;
        position: absolute;
        top: -72px !important;
        left: 0 !important;
      }
    }
    .cb-meta-tags,
    .cb-card-action,
    .cb-btn {
      display: none !important;
    }
    .cb-card-label {
      font-weight: 500;
      text-align: center;
      color: $white !important;
      background-color: $black !important;
      color-adjust: exact !important;
      -webkit-print-color-adjust: exact !important;
    }
  }

  // Horizontal Carousel
  .cb-horizontal-carousel {
    .cb-horizontal-nav-right,
    .cb-horizontal-nav-left {
      border: 0 !important;
      a {
        display: none;
        visibility: hidden;
      }
    }

    .cb-horizontal-carousel-nav {
      overflow: auto !important;
    }

    .cb-horizontal-carousel-content {
      width: 100%;
    }
  }

  // Box Shadow
  .cb-box-shadow,
  .cb-box-shadow-light {
    border: 1px solid $black1;
  }

  .cb-box-shadow-right,
  .cb-box-shadow-right-light {
    border-right: 1px solid $black1;
  }

  .cb-box-shadow-left,
  .cb-box-shadow-left-light {
    border-left: 1px solid $black1;
  }

  .cb-box-shadow-bottom,
  .cb-box-shadow-bottom-light {
    border-bottom: 1px solid $black1;
  }

  .cb-box-shadow-top,
  .cb-box-shadow-top-light {
    border-top: 1px solid $black1;
  }

  // These elements wont be displayed in Print mode
  .cb-popover,
  .cb-tooltip {
    display: none !important;
  }

  // Local Navigation
  .cb-local-navigation {
    position: relative !important;
    background-color: $white;

    .cb-nav-items {
      display: none !important;
    }

    .cb-site-name {
      a {
        &::after {
          content: "" !important;
        }
      }
    }
  }

  .cb-main-content,
  main {
    width: 100% !important;
  }

  .cb-dev-env.cb-show-breakpoints::before {
    content: "" !important;
    display: none !important;
    padding: 0;
  }

  // ------------- NO PRINT
  // UTILS
  .cb-no-print {
    @include no();
  }

  // Images
  .cb-no-print-img {
    img {
      display: none !important;
    }
  }

  .cb-no-print-border {
    border: 0 !important;
  }

  .cb-no-print-href {
    a {
      &::after {
        content: "" !important;
      }
    }
  }

  .cb-no-page-break {
    page-break-inside: avoid;
  }

  .cb-page-break {
    page-break-before: always;
    page-break-after: always;
  }

  .cb-page-break-after {
    page-break-after: always;
  }

  .cb-page-break-before {
    page-break-before: always;
  }

  // ------------- Widgets
  div[id^="cb-atlas-header"] {
    border-top: 0 !important;
    border-bottom: 1px solid $black;

    a[href="#widget-menu-control"],
    a[href="#widget-login-control"],
    div[data-cbtrack-widget="search"] {
      display: none !important;
    }

    a {
      &.cb-glyph-logo.cb-logo {
        &::after {
          content: "" !important;
        }
      }
    }
  }

  div[id^="cb-atlas-footer"] {
    > div {
      &:first-of-type {
        border-top: 1px solid $black !important;

        a {
          display: none !important;
        }
      }

      &:nth-of-type(2) {
        display: none !important;
      }
    }

    a {
      &::after {
        content: "" !important;
      }
    }
  }
}

// ------------- Convert xs to md
@mixin print-layout() {
  // xs to act like md
  @include make-grid-columns-print();
}

// ------------- Select

@mixin select-print($color, $position) {
  .cb-select-container {
    span {
      &.cb-select {
        > span {
          border-color: $color !important;
        }
      }
    }
  }
}

// ------------- Browser Specific
// retiring
@mixin print-browser-apricot() {
  [class*="firefox"],
  [class*="ie"]:not([class*="gradient"]) {
    .cb-event-timeline {
      > ol {
        > li {
          .cb-date-time-tags {
            p.cb-event-stamp {
              color: #000 !important;
              background-color: $white !important;
              color-adjust: exact !important;
              -webkit-print-color-adjust: exact !important;
              padding-left: 0;
            }
          }
        }
      }
    }
  }
}

// iOS Print
@mixin print-iOS-apricot() {
  body {
    font-family: sans-serif;
  }
  @for $i from 1 through 6 {
    h#{$i},
    .cb-h#{$i} {
      font-family: sans-serif;
    }
  }

  .cb-btn,
  .cb-font-small,
  .cb-font-xsmall,
  .cb-font-regular,
  .cb-no-font {
    font-family: sans-serif;
  }
  .cb-btn-row-sm {
    .cb-btn {
      font-family: sans-serif;
    }
  }
}

// ------------- Modal
@mixin print-modal() {
  // Only apply of a modal is open
  .cb-modal-open {
    * {
      visibility: hidden;
    }

    .cb-modal {
      &.cb-open {
        visibility: visible !important;

        .cb-modal-overlay {
          visibility: visible !important;
          justify-content: flex-start;
          align-items: flex-start;

          * {
            visibility: visible !important;
          }

          .cb-modal-container {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }

          .cb-modal-header {
            .cb-btn-close {
              display: none !important;
            }
          }

          .cb-modal-content {
            height: auto !important;
          }
        }
      }
    }
  }
}
